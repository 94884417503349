import React, { useContext, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { GlobalContext } from "../context/GlobalState";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "./Modal";
import ExtractedImagesTable from "./ExtractedImagesTable";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button as MuiButton,
  Box,
} from "@mui/material";
import "./ViewCheque.css";
import "react-lazy-load-image-component/src/effects/blur.css";

const ViewCheque = () => {
  const { chequeId } = useParams();
  const {
    getChequeDetails,
    user,
    preProcessAgain,
    deleteCheque,
    getAccountDetails,
  } = useContext(GlobalContext);
  const [cheque, setCheque] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureSrc, setSignatureSrc] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [parsedMetadata, setParsedMetadata] = useState({});
  const [data, setData] = useState({});
  const [openSignatureCompare, setOpenSignatureCompare] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [extractedSignature, setExtractedSignature] = useState(null);

  const handleImageClick = (imageSrc) => {
    setSignatureSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSignatureSrc("");
  };

  useEffect(() => {
    console.log(extractedSignature);
  }, [extractedSignature]);

  // Fetch cheque details and parse metadata and data from the retrieved cheque details.
  const fetchChequeDetails = async () => {
    setLoading(true);
    try {
      // Clear previous values
      setData({});
      setParsedMetadata({});
      setExtractedSignature(null);

      // Get the cheque details from backend
      const chequeDetails = await getChequeDetails(chequeId);
      setCheque(chequeDetails);

      // Extract the signature image from the list of extracted images (if available)
      setExtractedSignature(
        chequeDetails?.extractedImage?.find((img) => img.region === "signature")
      );

      // Get customer account details if accountNumber exists.
      if (chequeDetails?.accountNumber) {
        const accountDetails = await getAccountDetails(
          chequeDetails.accountNumber
        );
        if (accountDetails && accountDetails.type === "User") {
          setCustomer(accountDetails?.user);
        } else {
          setCustomer(accountDetails?.teams?.users[0]);
        }
      }

      // Parse metadata from the newly received chequeDetails
      if (chequeDetails?.metadata) {
        let metadata = chequeDetails.metadata
          .replace(/'/g, '"')
          .replace("True", "true")
          .replace("False", "false");
        try {
          setParsedMetadata(JSON.parse(metadata));
        } catch (error) {
          console.error("Error parsing metadata:", error);
        }
      }
      // Parse data from chequeDetails.
      if (chequeDetails?.data) {
        let d = chequeDetails.data
          .replace(/'/g, '"')
          .replace("True", "true")
          .replace("False", "false");
        try {
          setData(JSON.parse(d));
        } catch (error) {
          console.error("Error parsing data:", error);
        }
      }
    } catch (error) {
      console.error("Error fetching cheque details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Delete cheque and navigate back to the list view.
  const callDeleteCheque = async () => {
    try {
      await deleteCheque(chequeId);
      navigate("/cheque/view");
    } catch (error) {
      console.error("Error deleting cheque:", error);
    }
  };

  // Call to reprocess cheque on backend, and then fetch updated details.
  const callPreprocessAgain = async (model) => {
    try {
      // Clear the states before calling preprocess
      setData({});
      setParsedMetadata({});
      await preProcessAgain(chequeId, model);
      fetchChequeDetails();
    } catch (error) {
      console.error("Error calling preprocess again:", error);
    }
  };

  // Function to clean and capitalize text for comparison.
  const cleanAndCapitalizeText = (text) => {
    return text
      ?.toLowerCase()
      ?.replace(/[,]/g, "")
      ?.split(" ")
      ?.filter((word) => word && word !== "and" && word !== "only")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  };

  // Function that highlights differences between detected and corrected text.
  const highlightDifferences = (detected, corrected) => {
    if (!detected || !corrected) return corrected;

    const detectedWords = cleanAndCapitalizeText(detected);
    const correctedWords = cleanAndCapitalizeText(corrected);

    return correctedWords.map((word, index) => {
      const isDifferent = word !== detectedWords[index];
      return (
        <span
          key={index}
          style={{
            fontWeight: isDifferent ? "bold" : "inherit",
            color: isDifferent ? "blue" : "inherit",
            marginRight: "4px",
          }}
        >
          {word}
        </span>
      );
    });
  };

  // Determine the processing status
  const getProcessingStatus = () => {
    // Check if all necessary data is present
    if (
      data?.similarity === undefined ||
      data?.label === undefined ||
      parsedMetadata?.is_amount_valid === undefined
    ) {
      return { message: "The cheque is being processed.", color: "grey" };
    }

    // Determine if the signature is genuine
    // Adjust the condition based on how 'data.label' represents genuineness
    // For example, assuming 'genuine' indicates a genuine signature
    const isSignatureGenuine = data.label.toLowerCase() === "genuine";

    // Check if the amount is valid
    const isAmountValid = parsedMetadata.is_amount_valid;

    if (isSignatureGenuine && isAmountValid) {
      return { message: "The cheque is good to proceed.", color: "green" };
    } else {
      return {
        message: "The cheque is not good to proceed.",
        color: "red",
      };
    }
  };

  // On initial mount or when the chequeId changes, check for user and fetch cheque details.
  useEffect(() => {
    if (!user) {
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    } else {
      fetchChequeDetails();
    }
  }, [user, chequeId, navigate]);

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized Access</h1>;
  }

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading cheque details...</p>
        </div>
      </div>
    );
  }

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const day = dateString.substring(0, 2);
    const month = dateString.substring(2, 4);
    const year = dateString.substring(4, 8);
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="view-cheque-container">
      {/* Status Banner */}
      <div
        className={`status-banner ${
          getProcessingStatus().color === "green"
            ? "status-good"
            : getProcessingStatus().color === "red"
            ? "status-bad"
            : "status-incomplete"
        }`}
      >
        {getProcessingStatus().message}
      </div>
      <h2 className="page-title">Cheque Details</h2>

      {/* Cheque Details Section */}
      <div className="detail-item">
        <strong>Cheque ID:</strong> {cheque?.chequeId ?? "N/A"}
      </div>

      <div className="detail-item account-number-item">
        <strong>Account Number:</strong> {cheque?.accountNumber ?? "N/A"}
        <MuiButton
          variant="contained"
          color="primary"
          style={{ marginLeft: "15px" }}
          onClick={() => navigate(`/customer/view/${customer?.userId ?? ""}`)}
        >
          View Account
        </MuiButton>
      </div>

      <div className="detail-item">
        <strong>Cheque Image:</strong>
        {cheque?.chequeImage ? (
          <>
            <LazyLoadImage
              src={cheque.chequeImage}
              alt={`Cheque ${cheque?.chequeId ?? "N/A"}`}
              effect="blur"
              onClick={() => handleImageClick(cheque.chequeImage)}
              className="cheque-image"
            />
            <a
              href={cheque.chequeImage}
              download={`Cheque_${cheque?.chequeId ?? "Cheque"}.jpg`}
              className="btn-download"
            >
              Download
            </a>
          </>
        ) : (
          <span>No image available</span>
        )}
      </div>

      <ExtractedImagesTable
        extractedImages={cheque?.extractedImage}
        onImageClick={handleImageClick}
      />

      <div className="button-group">
        <MuiButton
          variant="contained"
          color="secondary"
          onClick={() => setOpenSignatureCompare(true)}
        >
          Compare Signature
        </MuiButton>
      </div>
      <br />

      {/* Metadata Section */}
      <div className="metadata-section">
        <h3>Cheque Data</h3>
        <div className="detail-item">
          <p>
            <strong>Similarity Score:</strong>{" "}
            {data?.similarity !== undefined
              ? `${(data.similarity * 100).toFixed(2)}%`
              : "Pending Processing"}
          </p>
          <p>
            <strong>Signature Status:</strong>{" "}
            {data?.label
              ? data.label.charAt(0).toUpperCase() + data.label.slice(1)
              : "Pending Processing"}
          </p>
        </div>
        <div className="detail-item">
          <strong>Detected Cheque Date:</strong>{" "}
          {formatDate(parsedMetadata?.date)}
        </div>
        <div className="detail-item">
          <strong>Detected Account Number:</strong>{" "}
          {parsedMetadata?.account_number ?? "N/A"}
        </div>
        <div className="detail-item">
          <strong>Detected Courtesy Amount (Numeric):</strong> ₹
          {parsedMetadata?.courtesy_amount ?? "N/A"}
        </div>
        <div className="detail-item">
          <strong>Detected Legal Amount (Words):</strong>{" "}
          {parsedMetadata?.legal_amount_original ?? "N/A"}
        </div>
        <div
          className="detail-item"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <strong style={{ flex: "1 1 50%" }}>
            Auto-Corrected Legal Amount (Words):
          </strong>
          <div
            style={{
              flex: "1 1 auto",
              minWidth: "50%",
              maxWidth: "50%",
              display: "flex",
              justifyContent: "flex-end",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {highlightDifferences(
              parsedMetadata?.legal_amount_original,
              parsedMetadata?.legal_amount
            ) || "N/A"}
          </div>
        </div>
        <div className="detail-item">
          <strong>Numeric Value (Words to Numbers):</strong> ₹
          {parsedMetadata?.legal_amount_numeric ?? "N/A"}
        </div>
        <div className="detail-item">
          <strong>Comparison of Words and Numeric Amounts:</strong>{" "}
          {parsedMetadata?.is_amount_valid !== undefined &&
          parsedMetadata?.is_amount_valid !== null ? (
            <span
              style={{
                color: parsedMetadata.is_amount_valid ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {parsedMetadata.is_amount_valid ? "Match" : "Mismatch"}
            </span>
          ) : (
            "N/A"
          )}
        </div>
        {/* <div className="detail-item">
          <strong>Processing Time:</strong>{" "}
          <span style={{ fontWeight: "bold" }}>
            {(
              parseFloat(data?.time_taken) +
              parseFloat(parsedMetadata?.time_taken || 0)
            ).toFixed(2)}
            {" s"}
          </span>
        </div> */}
        <div className="detail-item">
          <strong>Model Used:</strong>{" "}
          <span style={{ fontWeight: "bold" }}>
            {(cheque?.model?.split(".")[0] || "N/A").toUpperCase()}
          </span>
        </div>
      </div>

      {/* Buttons Section */}
      <div className="button-group">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 2,
            mt: 4,
          }}
        >
          <MuiButton
            variant="contained"
            color="warning"
            onClick={() => callPreprocessAgain("v2")}
          >
            Process-Using-V2
          </MuiButton>
          {/* <MuiButton
            variant="contained"
            color="warning"
            onClick={() => callPreprocessAgain("v3")}
          >
            Process-Using-V1-38MB
          </MuiButton> */}
          <MuiButton
            variant="contained"
            color="warning"
            onClick={() => callPreprocessAgain("v1")}
          >
            {/* Process-Using-V1-53MB */}
            Process-Using-V1
          </MuiButton>
          {/* <MuiButton
            variant="contained"
            color="warning"
            onClick={() => callPreprocessAgain("v4")}
          >
            Process-Using-V1-118MB
          </MuiButton> */}
        </Box>
        <br />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: 2,
            mt: 1,
          }}
        >
          <MuiButton
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
          >
            Back
          </MuiButton>
          <MuiButton
            variant="contained"
            color="error"
            onClick={callDeleteCheque}
          >
            Delete
          </MuiButton>
          <MuiButton
            variant="contained"
            color="primary"
            onClick={fetchChequeDetails}
          >
            Refresh
          </MuiButton>
        </Box>
      </div>

      {/* Material UI Dialog for Comparing Signatures */}
      <Dialog
        open={openSignatureCompare}
        onClose={() => setOpenSignatureCompare(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Compare Signatures</DialogTitle>
        <DialogContent dividers style={{ textAlign: "center" }}>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <LazyLoadImage
              src={customer?.signature}
              alt="Signature 1"
              effect="blur"
              className="cheque-image"
              style={{
                maxWidth: "250px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
              onClick={() => handleImageClick(customer?.signature)}
            />
            <LazyLoadImage
              src={extractedSignature?.imageUrl}
              alt="Signature 2"
              effect="blur"
              className="cheque-image"
              style={{
                maxWidth: "250px",
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
              onClick={() => handleImageClick(extractedSignature?.imageUrl)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <MuiButton
            onClick={() => setOpenSignatureCompare(false)}
            color="primary"
          >
            Close
          </MuiButton>
        </DialogActions>
      </Dialog>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={signatureSrc}
      />
    </div>
  );
};

export default ViewCheque;
