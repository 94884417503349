import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useParams, useNavigate } from "react-router-dom";
import Modal from "./Modal";
import "./CustomerDetails.css"; // Ensure you link the updated CSS

const CustomerDetails = () => {
  const { userId } = useParams();
  const { getCustomerDetails, user } = useContext(GlobalContext);
  const [customer, setCustomer] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [signatureSrc, setSignatureSrc] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    } else {
      getCustomerDetails(userId).then((data) => {
        setCustomer(data);
      });
    }
  }, [user, userId, navigate, getCustomerDetails]);

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  if (!customer) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Customer Details...</p>
        </div>
      </div>
    );
  }

  const handleImageClick = () => {
    setSignatureSrc(customer.signature);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSignatureSrc("");
  };

  return (
    <div className="customer-details-container">
      <h2 className="page-title">Customer Details</h2>
      <div className="details-wrapper">
        <div className="detail-item">
          <strong>Customer ID:</strong> {customer.userId}
        </div>
        <div className="detail-item">
          <strong>Name:</strong> {customer.name}
        </div>
        <div className="detail-item">
          <strong>Signature:</strong>
          <img
            src={customer.signature}
            alt={`Signature of ${customer.name}`}
            className="signature-image"
            onClick={handleImageClick}
          />
        </div>
        <div className="detail-item">
          <strong>Account Number:</strong> {customer?.account?.accountNumber}
        </div>
      </div>
      <div className="action-buttons">
        <button className="btn-back" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        imageSrc={signatureSrc}
      />
    </div>
  );
};

export default CustomerDetails;
