import React, { useContext, useState } from "react";
import VerticalNavbar from "./VerticalNavbar";
import HorizontalNavbar from "./HorizentalNavbar";
import { Outlet } from "react-router-dom";
import "./Layout.css"; // Ensure you have this CSS file linked
import { GlobalContext } from "../context/GlobalState";

const Layout = () => {
  const { isVerticalNavbarOpen, openVerticalNavbar, closeVerticalNavbar } =
    useContext(GlobalContext);

  const toggleNavbar = () => {
    if (isVerticalNavbarOpen) {
      closeVerticalNavbar();
    } else {
      openVerticalNavbar();
    }
  };

  return (
    <div className="layout">
      <HorizontalNavbar />
      <div className="main-content">
        <VerticalNavbar
          isOpen={isVerticalNavbarOpen}
          toggleNavbar={toggleNavbar}
        />
        <div
          className="content-container"
          style={{
            marginLeft: isVerticalNavbarOpen ? "250px" : "50px", // Adjust content position
            transition: "margin-left 0.3s ease",
          }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
