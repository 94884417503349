import React, { useContext, useEffect, useState } from "react";
import "./Login.css";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const { login, user, sendGmailLogin } = useContext(GlobalContext);
  const navigate = useNavigate();

  const googleSignIn = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(false);
      sendGmailLogin(tokenResponse.access_token);
    },
    onError: (error) => {
      setLoading(false);
      console.error(error, "Login error");
    },
  });

  const handleGoogleLogin = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader
    try {
      googleSignIn();
    } catch (error) {
      setLoading(false);
      console.error(error, "Login error");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show loader
    login(email, password)
      .then(() => {
        setLoading(false); // Hide loader
      })
      .catch((error) => {
        setLoading(false); // Hide loader
        console.error(error, "Login error");
      });
  };

  useEffect(() => {
    if (user) {
      setLoading(false); // Hide loader when user is logged in
      navigate("/"); // Redirect to home
    }
  }, [user, navigate]);

  return (
    <div className="login-page">
      <div className="login-container">
        <h2 className="login-title">Sign In</h2>
        {loading ? (
          <div className="loader"></div> // Loader component
        ) : (
          <form
            className="login-form"
            onSubmit={handleSubmit}
            method="POST"
            action="/login"
          >
            <div className="input-group">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                autoComplete="username"
              />
            </div>
            <div className="input-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
                autoComplete="current-password"
              />
            </div>
            <button type="submit" className="login-button">
              Sign In
            </button>
            {/* <button
              className="login-button google-login"
              onClick={handleGoogleLogin}
            >
              Sign in with Google
            </button> */}
          </form>
        )}
      </div>
    </div>
  );
};

export default Login;
