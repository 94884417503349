import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateAdmin.css"; // Make sure this CSS file is linked
import { GlobalContext } from "../context/GlobalState";

const CreateAdmin = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state
  const navigate = useNavigate();
  const { createAdmin, user } = useContext(GlobalContext);

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !password) {
      setMessage("Please provide all required fields.");
      return;
    }

    // Set loader to true and disable submit
    setIsSubmitting(true);

    try {
      await createAdmin(name, email, password);
      setMessage("Admin created successfully.");
      setName("");
      setEmail("");
      setPassword("");
    } catch (err) {
      console.log(err);
      setMessage("Error creating admin. Please try again.");
    } finally {
      setIsSubmitting(false); // Hide loader and enable submit after submission
    }

    // Hide message after 5 seconds
    setTimeout(() => setMessage(""), 5000);
  };

  return (
    <div className="submit-admin-container">
      <div className="form-container">
        <h2>Create Admin</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter admin name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter admin email"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter admin password"
              required
            />
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting} // Disable button while submitting
          >
            {isSubmitting ? "Submitting..." : "Create Admin"}{" "}
            {/* Show loader text */}
          </button>
          {isSubmitting && <div className="loader"></div>}{" "}
          {/* Display loader */}
          {message && (
            <div
              className={`message ${
                message.startsWith("Error") ? "error" : "success"
              }`}
            >
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateAdmin;
