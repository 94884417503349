import React, { useReducer, createContext, useEffect } from "react";
import axios from "axios";
import AppReducer from "./AppReducer";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 60000,
});

const initialState = {
  user: null,
  error: null,
  loading: false,
  token: null,
  isVerticalNavbarOpen: false,
};

//creating context
export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const navigate = useNavigate();
  // useEffect(() => {
  //   console.log(state.user);
  // }, [state.user]);

  useEffect(() => {
    // check cookies if refresh token exists
    const refreshToken = Cookies.get("SignatureRefreshToken");
    if (refreshToken) {
      axiosInstance
        .post("/api/admin/refresh-token", { refresh_token: refreshToken })
        .then((res) => {
          axiosInstance.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data.token}`;
          dispatch({
            type: "LOGIN_SUCCESS",
            payload: { user: res.data.user, token: res.data.token },
          });
          navigate("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  // Add an axios interceptor for handling 401 errors globally
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logout(); // Automatically log out if 401 is received
      }
      return Promise.reject(error);
    }
  );

  const healthCheck = async () => {
    try {
      const res = await axiosInstance.get("/health");
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    healthCheck();
  }, []);

  const login = async (email, password) => {
    try {
      const res = await axiosInstance.post("/api/admin/login", {
        email: email,
        password: password,
      });
      // console.log(res.data);
      Cookies.set("SignatureRefreshToken", res.data.refreshToken, {
        expires: 7,
      });
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: email, token: res.data.token },
      });
      navigate("/");
    } catch (err) {
      dispatch({
        type: "LOGIN_FAIL",
        payload: err.response.data.detail,
      });
      console.log(err);
      throw err;
    }
  };

  const logout = () => {
    Cookies.remove("SignatureRefreshToken");
    delete axiosInstance.defaults.headers.common["Authorization"];
    dispatch({
      type: "LOGIN_SUCCESS",
      payload: { user: null, token: null },
    });
    navigate("/login");
  };

  const createAdmin = async (name, email, password) => {
    try {
      const res = await axiosInstance.post("/api/admin/create", {
        email: email,
        password: password,
        name: name,
      });
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const createCustomer = async (obj) => {
    try {
      const formData = new FormData();
      formData.append("name", obj.name);
      formData.append("userId", obj.customerId);
      formData.append("image", obj.image);
      const res = await axiosInstance.post("/api/customer/create", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const viewCustomer = async () => {
    try {
      const res = await axiosInstance.get("/api/customer/getAllUsers");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const sendGmailLogin = async (access_token, expiry) => {
    try {
      const res = await axiosInstance.post(`/api/admin/gmail-login`, {
        access_token,
        expiry,
      });
      Cookies.set("SignatureRefreshToken", res.data.refreshToken, {
        expires: 7,
      });
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: res.data.user, token: res.data.token },
      });
      navigate("/");
    } catch (err) {
      console.log(err);
      dispatch({
        type: "LOGIN_FAIL",
        payload: err.response.data.detail,
      });
    }
  };

  const getCustomerDetails = async (userId) => {
    try {
      const res = await axiosInstance.get(`/api/customer/${userId}`);
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomerIds = async () => {
    try {
      const res = await axiosInstance.get("/api/customer/getAllId");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const createTeam = async (obj) => {
    try {
      const res = await axiosInstance.post("/api/team/create", obj);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllTeamIds = async () => {
    try {
      const res = await axiosInstance.get("/api/team/getAllId");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getTeamDetails = async (teamId) => {
    try {
      const res = await axiosInstance.get(`/api/team/${teamId}`);
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const createCheque = async (obj) => {
    try {
      const res = await axiosInstance.post("/api/cheque/create", obj);
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCheques = async () => {
    try {
      const res = await axiosInstance.get("/api/cheque/");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getChequeDetails = async (chequeId) => {
    try {
      const res = await axiosInstance.get(`/api/cheque/${chequeId}`);
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const getAllChequeInQueue = async () => {
    try {
      const res = await axiosInstance.get("/api/chequeQueue");
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const openVerticalNavbar = () => {
    dispatch({
      type: "OPEN_VERTICAL_NAVBAR",
    });
  };

  const deleteCheque = async (chequeId) => {
    try {
      const res = await axiosInstance.delete(`/api/cheque/${chequeId}`);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const closeVerticalNavbar = () => {
    dispatch({
      type: "CLOSE_VERTICAL_NAVBAR",
    });
  };

  const preProcessAgain = async (chequeId, model) => {
    try {
      const res = await axiosInstance.get(
        model
          ? `/api/cheque/process-again/${chequeId}?model=${model}`
          : `/api/cheque/process-again/${chequeId}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  const getAccountDetails = async (accountNumber) => {
    try {
      const res = await axiosInstance.get(
        `/api/account/get-details/${accountNumber}`
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        user: state.user,
        error: state.error,
        loading: state.loading,
        token: state.token,
        login,
        logout,
        createAdmin,
        createCustomer,
        viewCustomer,
        sendGmailLogin,
        getCustomerDetails,
        getAllCustomerIds,
        createTeam,
        getAllTeamIds,
        getTeamDetails,
        createCheque,
        getAllCheques,
        getChequeDetails,
        getAllChequeInQueue,
        isVerticalNavbarOpen: state.isVerticalNavbarOpen,
        openVerticalNavbar,
        closeVerticalNavbar,
        preProcessAgain,
        deleteCheque,
        getAccountDetails,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
