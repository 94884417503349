import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./ExtractedImagesTable.css";

const ExtractedImagesTable = ({ extractedImages, onImageClick }) => {
  if (!extractedImages || extractedImages.length === 0) {
    return <p>No extracted images available.</p>;
  }

  return (
    <div className="aws-table-container">
      <table className="aws-table">
        <thead>
          <tr>
            <th className="aws-th">Name</th>
            <th className="aws-th">Image</th>
          </tr>
        </thead>
        <tbody>
          {extractedImages.map((imageData, index) => (
            <tr key={index} className="aws-tr">
              <td className="aws-td aws-name">
                {imageData.region
                  ?.split("_")
                  ?.map((w) => w.charAt(0).toUpperCase() + w.slice(1))
                  ?.join(" ")}
              </td>
              <td className="aws-td aws-image">
                <LazyLoadImage
                  src={imageData.imageUrl}
                  alt={imageData.region}
                  effect="blur"
                  onClick={() => onImageClick(imageData.imageUrl)}
                  className="aws-image-preview"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExtractedImagesTable;
