import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import "./ViewChequeList.css"; // Ensure you have this CSS file linked

const ViewChequeQueue = () => {
  const { getAllChequeInQueue, user } = useContext(GlobalContext);
  const [cheques, setCheques] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    } else {
      getAllChequeInQueue().then((data) => {
        setCheques(data);
        setLoading(false);
      });
    }
  }, [user, navigate, getAllChequeInQueue]);

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Cheques in Queue...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  return (
    <div className="view-cheque-list-container">
      <h2 className="page-title">Cheque Queue List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Cheque ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {cheques.length > 0 ? (
            cheques.map((cheque) => (
              <tr key={cheque.chequeId}>
                <td>{cheque.chequeId}</td>
                <td>
                  <button
                    className="view-button"
                    onClick={() => navigate(`/cheque/view/${cheque.chequeId}`)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2" className="text-center">
                No cheques found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewChequeQueue;
