import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import "./ViewChequeList.css"; // Ensure you have this CSS file linked

const ViewChequeList = () => {
  const { getAllCheques, user } = useContext(GlobalContext);
  const [cheques, setCheques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Function to display the Date in a readable format
  const formatDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });
  };

  useEffect(() => {
    if (!user) {
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    } else {
      getAllCheques().then((data) => {
        // Sort cheques in descending order based on createdAt date
        //  add serial number field
        data.forEach((cheque, index) => {
          cheque.serialNumber = index + 1;
        });
        const sortedCheques = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCheques(sortedCheques);
        setLoading(false);
      });
    }
  }, [user, navigate, getAllCheques]);

  // Function to get today's date in the format "Month Day, Year"
  const getTodayDate = () => {
    return new Date().toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Filter cheques based on the search query for both Cheque ID and Date
  const filteredCheques = cheques.filter((cheque) => {
    const chequeIdMatch = cheque.chequeId
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const dateMatch = formatDate(cheque.createdAt)
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    return chequeIdMatch || dateMatch;
  });

  // Function to clear search
  const clearSearch = () => {
    setSearchQuery("");
  };

  // Function to set today's date in the search field
  const searchToday = () => {
    setSearchQuery(getTodayDate()); // Set only the date part for search
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Cheques...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  return (
    <div className="view-cheque-list-container">
      <h2 className="page-title">Cheque List</h2>

      {/* Search bar and action buttons */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Cheque ID or Date"
          value={searchQuery}
          style={{
            width: "73%",
          }}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="action-button" onClick={searchToday}>
          Search Today's Cheques
        </button>
        <button
          className="action-button"
          onClick={clearSearch}
          style={{
            backgroundColor: "orange",
          }}
        >
          Clear Search
        </button>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th>Serial No.</th>
            <th>Cheque ID</th>
            <th>Date Created</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredCheques.length > 0 ? (
            filteredCheques.map((cheque) => (
              <tr key={cheque.chequeId}>
                <td>{cheque.serialNumber}</td>
                <td>{cheque.chequeId}</td>
                <td>{formatDate(cheque.createdAt)}</td>
                <td>
                  <button
                    className="view-button"
                    onClick={() => navigate(`/cheque/view/${cheque.chequeId}`)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="text-center">
                No cheques found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewChequeList;
