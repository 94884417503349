export default (state, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGIN_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "CLOSE_VERTICAL_NAVBAR":
      return {
        ...state,
        isVerticalNavbarOpen: false,
      };
    case "OPEN_VERTICAL_NAVBAR":
      return {
        ...state,
        isVerticalNavbarOpen: true,
      };
    default:
      return state;
  }
};
