import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./VerticalNavbar.css";

const VerticalNavbar = ({ isOpen, toggleNavbar }) => {
  // Use useLocation to get the current path
  const location = useLocation();
  const path = location.pathname.split("/")[1]; // Get the first path segment

  return (
    <div className={`vertical-navbar ${isOpen ? "open" : "closed"}`}>
      <div className="navbar-header">
        <div className="navbar-logo">
          {/* <img src="/path/to/your/logo.png" alt="Logo" /> */}
        </div>
        {isOpen ? (
          <button className="navbar-toggle-1" onClick={toggleNavbar}>
            ✖
          </button>
        ) : (
          <button className="navbar-toggle" onClick={toggleNavbar}>
            ☰
          </button>
        )}
      </div>
      {isOpen && (
        <ul className="navbar-menu">
          {path === "admin" && (
            <>
              <li>
                <NavLink to="/admin/create" activeClassName="active">
                  Create
                </NavLink>
              </li>
            </>
          )}
          {path === "team" && (
            <>
              <li>
                <NavLink to="/team/create" activeClassName="active">
                  Create
                </NavLink>
                <NavLink to="/team/view" activeClassName="active">
                  View
                </NavLink>
              </li>
            </>
          )}
          {path === "cheque" && (
            <>
              <li>
                <NavLink to="/cheque/create" activeClassName="active">
                  Create
                </NavLink>
                <NavLink to="/cheque/view" activeClassName="active">
                  View
                </NavLink>
                <NavLink to="/cheque/queue" activeClassName="active">
                  Queue
                </NavLink>
              </li>
            </>
          )}
          {path === "customer" && (
            <>
              <li>
                <NavLink to="/customer/create" activeClassName="active">
                  Create
                </NavLink>
              </li>
              <li>
                <NavLink to="/customer/view" activeClassName="active">
                  View
                </NavLink>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};

export default VerticalNavbar;
