import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import "./ViewUsers.css";

const ViewUsers = () => {
  const { viewCustomer, user } = useContext(GlobalContext);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setTimeout(() => {
      viewCustomer().then((res) => {
        setUsers(res);
        setLoading(false);
      });
    }, 50);
  }, [viewCustomer]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    }
  }, [user, navigate]);

  const filteredUsers = users?.filter(
    (user) =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.userId.toLowerCase().includes(search.toLowerCase()) ||
      user?.account?.accountNumber.toLowerCase().includes(search.toLowerCase())
  );

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Users...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  const handleViewClick = (userId) => {
    navigate(`/customer/view/${userId}`);
  };

  return (
    <div className="view-users-container">
      <h2 className="page-title">Customer List</h2>
      <div className="search-bar">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name, customer ID, or account number"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {search && (
          <button className="btn-clear" onClick={() => setSearch("")}>
            Clear
          </button>
        )}
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Customer ID</th>
            <th>Name</th>
            <th>Signature</th>
            <th>Account</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers?.length > 0 ? (
            filteredUsers?.map((user) => (
              <tr key={user.userId}>
                <td>{user.userId}</td>
                <td>{user.name}</td>
                <td>
                  <img
                    src={user.signature}
                    alt={`Signature of ${user.name}`}
                    className="signature-image"
                  />
                </td>
                <td>{user?.account?.accountNumber}</td>
                <td>
                  <button
                    className="btn-view"
                    onClick={() => handleViewClick(user.userId)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="text-center">
                No customers found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewUsers;
