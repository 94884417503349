import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./CreateCheque.css";
import { GlobalContext } from "../context/GlobalState";

const CreateCheque = () => {
  const [accountNumber, setAccountNumber] = useState("");
  const [chequeImage, setChequeImage] = useState(null);
  const [model, setModel] = useState("v1");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state
  const [chequeData, setChequeData] = useState({});

  const navigate = useNavigate();
  const { createCheque, user } = useContext(GlobalContext);

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    }
  }, [user, navigate]);

  // Automatically clear the message after 5 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 10000); // Change to the time you'd like the banner to disappear (5000ms = 5 seconds)

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!accountNumber || !chequeImage) {
      setMessage("Please provide both account number and cheque image.");
      return;
    }

    setIsSubmitting(true); // Activate loader

    const formData = new FormData();
    formData.append("accountNumber", accountNumber);
    formData.append("image", chequeImage);
    formData.append("model", model);

    try {
      const data = await createCheque(formData);
      setChequeData(data);
      setMessage("Cheque submitted successfully.");
      setAccountNumber("");
      setChequeImage(null);
    } catch (err) {
      setMessage("Error submitting cheque. Please try again later.");
    } finally {
      setIsSubmitting(false); // Deactivate loader
    }
  };

  useEffect(() => {
    console.log(chequeData);
  }, [chequeData]);

  return (
    <div className="submit-cheque-container">
      <div className="form-container">
        <h2>Submit Cheque</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="input-group">
            <label htmlFor="accountNumber">Account Number</label>
            <input
              id="accountNumber"
              type="text"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
              placeholder="Enter account number"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="chequeImage">Cheque Image</label>
            <input
              id="chequeImage"
              type="file"
              accept="image/*"
              onChange={(e) => setChequeImage(e.target.files[0])}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="model">Model</label>
            <select
              id="model"
              required
              value={model}
              onChange={(e) => setModel(e.target.value)}
              style={{
                padding: "0.5rem",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            >
              <option value="v1" defaultChecked>
                V1
              </option>
              <option value="v2">V2</option>
            </select>
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <>
                Submitting <div className="loader"></div>
              </>
            ) : (
              "Submit Cheque"
            )}
          </button>
          {message && (
            <>
              <div
                className={`message ${
                  message.startsWith("Error") ? "error" : "success"
                }`}
              >
                {message}
              </div>
              {chequeData && chequeData.chequeId && (
                <button
                  className="view-button"
                  onClick={() =>
                    navigate(`/cheque/view/${chequeData.chequeId}`)
                  }
                >
                  View Cheque
                </button>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateCheque;
