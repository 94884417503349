import React from "react";
import { Link } from "react-router-dom";
import "./FrontPage.css"; // Ensure you have this CSS file linked

const FrontPage = () => {
  return (
    <div className="front-page-container">
      <header className="header">
        <h1>Signature Extraction & Verification System</h1>
      </header>
      <main className="main-content">
        <section className="intro">
          <h2>Welcome to the Signature Extraction and Verification System</h2>
          <p>
            This application allows you to manage customer accounts, upload
            signatures, and verify signatures extracted from bank cheques.
          </p>
          <p>
            <strong>Key Features:</strong>
          </p>
          <ul>
            <li>Create and manage customer accounts</li>
            <li>Upload and store customer signatures</li>
            <li>Extract signatures from cheques</li>
            <li>Verify signatures using advanced algorithms</li>
          </ul>
        </section>
      </main>
      {/* <footer className="footer">
        <p>
          Contact:{" "}
          <a href="mailto:support@aeroqube.com">support@aeroqube.com</a>
        </p>
      </footer> */}
    </div>
  );
};

export default FrontPage;
