import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import "./ViewTeams.css";

const ViewTeams = () => {
  const { getAllTeamIds, user } = useContext(GlobalContext);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      getAllTeamIds().then((res) => {
        setTeams(res);
        setLoading(false);
      });
    }
  }, [user, getAllTeamIds]);

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    }
  }, [user, navigate]);

  const handleViewClick = (teamId) => {
    navigate(`/team/view/${teamId}`);
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Teams...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  return (
    <div className="view-teams-container">
      <h2 className="page-title">Teams List</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Team ID</th>
            <th>Account</th>
            <th>Number of People</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {teams.length > 0 ? (
            teams.map((team) => (
              <tr key={team.teamId}>
                <td>{team.teamId}</td>
                <td>{team?.account?.accountNumber}</td>
                <td>{team?.users?.length}</td>
                <td>
                  <button
                    className="view-button"
                    onClick={() => handleViewClick(team.teamId)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No teams found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ViewTeams;
