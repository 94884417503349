import React, { useContext, useEffect } from "react";
import "./Modal.css"; // Ensure you have CSS for modal styling
import { GlobalContext } from "../context/GlobalState";

const Modal = ({ isOpen, onClose, imageSrc }) => {
  const { closeVerticalNavbar } = useContext(GlobalContext);
  useEffect(() => {
    if (isOpen) closeVerticalNavbar();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="btn-close" onClick={onClose}></button>
        <img src={imageSrc} alt="Enlarged Signature" className="modal-image" />
      </div>
    </div>
  );
};

export default Modal;
