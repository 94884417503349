import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import CreateAdmin from "./pages/CreateAdmin";
import CreateCustomer from "./pages/CreateCustomer";
import ViewUsers from "./pages/ViewUsers";
import Layout from "./pages/Layout";
import CustomerDetails from "./pages/CustomerDetails";
import CreateTeam from "./pages/CreateTeam";
import ViewTeams from "./pages/ViewTeams";
import ViewTeam from "./pages/ViewTeam";
import CreateCheque from "./pages/CreateCheque";
import ViewChequeList from "./pages/ViewChequeList";
import ViewCheque from "./pages/ViewCheque";
import ViewChequeQueue from "./pages/ViewChequeQueue";
import FrontPage from "./pages/FrontPage";
import { useContext, useEffect } from "react";
import { GlobalContext } from "./context/GlobalState";

function App() {
  const { openVerticalNavbar, closeVerticalNavbar, user } =
    useContext(GlobalContext);

  const location = useLocation();
  useEffect(() => {
    if (!user) {
      closeVerticalNavbar();
    } else {
      if (location.pathname !== "/") openVerticalNavbar();
      else closeVerticalNavbar();
    }
  }, [location, user]);

  return (
    <>
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/customer/create" element={<CreateCustomer />} />
          <Route path="/customer/view" element={<ViewUsers />} />
          <Route path="/" element={<FrontPage />} />
          <Route path="/admin/create" element={<CreateAdmin />} />
          <Route path="/team/create" element={<CreateTeam />} />
          <Route path="/cheque/create" element={<CreateCheque />} />
          <Route path="/team/view" element={<ViewTeams />} />
          <Route path="/cheque/view" element={<ViewChequeList />} />
          <Route path="/cheque/queue" element={<ViewChequeQueue />} />
          <Route path="/team/view/:teamId" element={<ViewTeam />} />
          <Route path="/customer/view/:userId" element={<CustomerDetails />} />
          <Route path="/cheque/view/:chequeId" element={<ViewCheque />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
