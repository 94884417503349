import React, { useContext } from "react";
import "./HorizentalNavbar.css"; // Ensure you have this CSS file linked for custom styles
import { GlobalContext } from "../context/GlobalState";
import { NavLink, useNavigate } from "react-router-dom";

const HorizontalNavbar = () => {
  const { token, user, logout } = useContext(GlobalContext);
  const navigate = useNavigate();

  return (
    <nav className="aero-navbar">
      <div className="aero-navbar-container">
        <div className="aero-navbar-left">
          <NavLink
            className="aero-navbar-brand"
            to="/"
            style={{
              marginRight: "15px",
            }}
          >
            <div className="aero-brand">
              ChequeVision
              <span
                className="aero-company-name"
                style={{
                  textAlign: "center",
                }}
              >
                by Aeroqube
              </span>
            </div>
          </NavLink>
          {user && (
            <>
              <NavLink className="aero-nav-link" to="/admin/create" end>
                Admin
              </NavLink>
              <NavLink className="aero-nav-link" to="/customer/view">
                User
              </NavLink>
              <NavLink className="aero-nav-link" to="/team/view">
                Team
              </NavLink>
              <NavLink className="aero-nav-link" to="/cheque/view">
                Cheque
              </NavLink>
            </>
          )}
        </div>
        <div className="aero-navbar-right">
          {user ? (
            <>
              <span className="aero-navbar-user">
                {user?.split("@")[0]?.toUpperCase()}
              </span>
              <button
                className="aero-navbar-btn aero-logout-btn"
                onClick={logout}
              >
                Logout
              </button>
            </>
          ) : (
            <button
              className="aero-navbar-btn"
              onClick={() => {
                navigate("/login");
              }}
            >
              Login
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default HorizontalNavbar;
