import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";
import "./CreateTeam.css"; // Ensure you have this CSS file linked

const CreateTeam = () => {
  const [name, setName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [teamIds, setTeamIds] = useState([]);
  const [message, setMessage] = useState(""); // For displaying messages
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state
  const { createTeam, user, getAllCustomerIds, getAllTeamIds } =
    useContext(GlobalContext);
  const navigate = useNavigate();

  // Fetch users and team IDs once user is authenticated
  useEffect(() => {
    if (user) {
      setIsSubmitting(true);
      getAllCustomerIds().then((res) => {
        setUsers(
          res.map((user) => ({ value: user.userId, label: user.userId }))
        );
      });

      getAllTeamIds().then((res) => {
        setTeamIds(res.map((team) => team.teamId));
        setIsSubmitting(false);
      });
    }
  }, [user, getAllCustomerIds, getAllTeamIds]);

  // Automatically clear the message after 5 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name || selectedUsers.length === 0) {
      setMessage("Please provide a team name and select at least one user.");
      return;
    }

    if (teamIds.includes(teamId)) {
      setMessage("The team ID already exists. Please choose a different ID.");
      return;
    }

    setIsSubmitting(true);

    createTeam({
      name: name,
      teamId: teamId,
      users: selectedUsers.map((user) => user.value),
    })
      .then(() => {
        setName("");
        setTeamId("");
        setSelectedUsers([]);
        setMessage("Team created successfully!");
      })
      .catch((error) => {
        setMessage(
          "An error occurred while creating the team. Please try again."
        );
        console.error(error);
      })
      .finally(() => setIsSubmitting(false));
  };

  // Redirect to login if not authenticated
  // Redirect to login if not authenticated
  // Redirect to login if not authenticated
  useEffect(() => {
    if (!user) {
      const currentPath = window.location.pathname;

      // Only save the current path if it's not '/login'
      if (currentPath !== "/login") {
        localStorage.setItem("redirectTo", currentPath); // Save the original path
        console.log(`Current path saved: ${currentPath}`); // Log the saved path
      }

      // Redirect to login
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="team-container">
      <div className="form-container">
        <h2 className="form-title">Create Team</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="teamName">Team Name</label>
            <input
              id="teamName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter team name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="teamId">Team ID</label>
            <input
              id="teamId"
              type="text"
              value={teamId}
              onChange={(e) => setTeamId(e.target.value)}
              placeholder="Enter team ID"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="members">Team Members</label>
            <Select
              id="members"
              isMulti
              options={users}
              value={selectedUsers}
              onChange={(selected) => setSelectedUsers(selected)}
              placeholder="Select users"
            />
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting} // Disable the button during submission
          >
            {isSubmitting ? "Creating..." : "Create Team"} {/* Loader text */}
          </button>
          {isSubmitting && <div className="loader"></div>} {/* Loader */}
          {message && (
            <div
              className={`message ${
                message.includes("error") ? "error" : "success"
              }`}
            >
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateTeam;
