import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/GlobalState";
import { useParams, useNavigate } from "react-router-dom";
import "./ViewTeam.css"; // Ensure you link the updated CSS

const ViewTeam = () => {
  const { teamId } = useParams();
  const { getTeamDetails, user } = useContext(GlobalContext);
  const [team, setTeam] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    } else {
      getTeamDetails(teamId).then((data) => {
        setTeam(data);
        setLoading(false);
      });
    }
  }, [user, teamId, navigate, getTeamDetails]);

  const handleViewMember = (memberId) => {
    navigate(`/customer/view/${memberId}`);
  };

  if (!user) {
    return <h1 className="unauthorized-message">Unauthorized</h1>;
  }

  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader-wrapper">
          <div className="loader-circle"></div>
          <p>Loading Team Details...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="view-team-container">
      <h2 className="page-title">Team Details</h2>

      <div className="team-details-section">
        <div className="detail-item">
          <strong>Team ID:</strong> {team.teamId}
        </div>
        <div className="detail-item">
          <strong>Team Name:</strong> {team.name}
        </div>
        <div className="detail-item">
          <strong>Account Number:</strong> {team.account?.accountNumber}
        </div>
      </div>

      <div className="team-members-section">
        <h3 className="section-title">Team Members</h3>
        <ul className="member-list">
          {team.users.map((member) => (
            <li key={member.userId} className="member-item">
              <span>{member.userId}</span>
              <button
                className="view-member-button"
                onClick={() => handleViewMember(member.userId)}
              >
                View
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="action-buttons">
        <button className="btn-back" onClick={() => navigate(-1)}>
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewTeam;
