import React, { useContext, useEffect, useRef, useState } from "react";
import "./CreateCustomer.css";
import { GlobalContext } from "../context/GlobalState";
import { useNavigate } from "react-router-dom";

const CreateCustomer = () => {
  const [name, setName] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [image, setImage] = useState(null);
  const [customerIds, setCustomerIds] = useState([]);
  const [message, setMessage] = useState(""); // To display success/error messages
  const [isSubmitting, setIsSubmitting] = useState(false); // Loader state
  const fileInputRef = useRef(null); // Ref for file input

  const { createCustomer, user, getAllCustomerIds } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Fetch customer IDs once user is authenticated
  useEffect(() => {
    if (user) {
      getAllCustomerIds().then((res) => {
        setCustomerIds(res.map((item) => item.userId));
      });
    }
  }, [user, getAllCustomerIds]);

  // Automatically clear the message after 5 seconds
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 5000); // 5-second timeout for the message banner

      return () => clearTimeout(timer); // Cleanup function
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (customerIds.includes(customerId)) {
      setMessage("Customer ID already exists. Please choose a different ID.");
      return;
    }

    setIsSubmitting(true); // Activate loader

    createCustomer({ name, customerId, image })
      .then(() => {
        setCustomerIds([...customerIds, customerId]); // Add new ID to the list
        setName("");
        setCustomerId("");
        setImage(null);
        fileInputRef.current.value = ""; // Clear file input
        setMessage("Customer created successfully!"); // Success message
      })
      .catch((error) => {
        setMessage(
          "An error occurred while creating the customer. Please try again."
        );
        console.error(error);
      })
      .finally(() => {
        setIsSubmitting(false); // Deactivate loader
      });
  };

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!user) {
      // Save the current path to localStorage
      localStorage.setItem("redirectTo", window.location.pathname);
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="customer-container">
      <div className="form-container">
        <h2 className="form-title">Create Customer</h2>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="input-group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter the customer's name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="customerId">Customer ID</label>
            <input
              id="customerId"
              type="text"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
              placeholder="Enter the customer ID"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="image">Image</label>
            <input
              id="image"
              type="file"
              ref={fileInputRef}
              accept="image/*"
              onChange={(e) => setImage(e.target.files[0])}
              required
            />
          </div>
          <button
            type="submit"
            className="submit-button"
            disabled={isSubmitting} // Disable the button during submission
          >
            {isSubmitting ? "Creating..." : "Create Customer"}{" "}
            {/* Loader text */}
          </button>
          {isSubmitting && <div className="loader"></div>}{" "}
          {/* Display loader */}
          {message && (
            <div
              className={`message ${
                message.includes("error") ? "error" : "success"
              }`}
            >
              {message}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateCustomer;
